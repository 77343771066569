import React from 'react'
import { Link } from 'gatsby'
import FullWidth from './fullWidth'
import CreateIcon from '../images/create.inline.svg'

import * as styles from './webReports.module.css'

const WebReports = () => {
  return (
    <FullWidth>
      <div id="reports" className={styles.center}>
        <CreateIcon />
        <h2>Create, share and collaborate on business reports</h2>
        <p>
          {process.env.GATSBY_PRODUCT_NAME} automates reporting for
          subscription-based businesses. You can easily annotate any data point
          and share reports with your investors or employees.
        </p>
        <Link to="/web">Learn more</Link>
      </div>
    </FullWidth>
  )
}

export default WebReports
