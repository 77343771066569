import React from 'react'
import classnames from 'classnames'

import * as styles from './integrations.module.css'
import * as sectionCss from './section.module.css'

const Integrations = ({ whiteBackground }) => {
  return (
    <div className={classnames('container', 'col-xxl-10', 'px-4', 'py-5')}>
      <div className={classnames('row', 'justify-content-center', 'align-items-center', 'g-5', 'py-5')}>
      <div className={styles.integrationsContent}>
        <div className={sectionCss.header}>
          <h2 id="integrations">Use integration or add data manually</h2>
        </div>
        <div className={styles.integrations}>
          <div className={classnames(styles.logo, styles.stripeLogo)}>
            <a href="/integrations/stripe">Stripe</a>
          </div>
          <div className={classnames(styles.logo, styles.chargebeeLogo)}></div>
          <div className={classnames(styles.logo, styles.slackLogo)}></div>
          <div className={classnames(styles.logo, styles.intercomLogo)}></div>
          <div className={classnames(styles.logo, styles.hubspotLogo)}></div>
          <div className={classnames(styles.logo, styles.zendeskLogo)}></div>
          <div className={classnames(styles.logo, styles.infaktLogo)}>
            <a href="/integrations/infakt">InFakt</a>
          </div>
          <div className={classnames(styles.logo, styles.fakturowniaLogo)}>
            <a href="/integrations/fakturownia">Fakturownia</a>
          </div>
          <div className={classnames(styles.logo, styles.wfirmaLogo)}>
            <a href="/integrations/wfirma">wFirma</a>
          </div>
          <div className={classnames(styles.logo, styles.ifirmaLogo)}>
            <a href="/integrations/ifirma">ifirma</a>
          </div>
        </div>
        <div>
          Using different tools? Integrate with our{' '}
          <a href="/docs/api">public API</a> or{' '}
          <a href={`mailto:${process.env.GATSBY_SUPPORT_EMAIL}`}>contact us</a>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Integrations
