import React from 'react'
import FullWidth from './fullWidth'
import SlackMrrImage from './slackMrrImage'
import FinanceMetrics from './financeMetrics'
import MetricSegmentation from './metricSegmentation'
import ProductMetrics from './productMetrics'
import WeeklyReports from './weeklyReports'

import * as styles from './section.module.css'

const SlackApp = () => {
  return (
    <div id="slack_app">
      <FullWidth>
        <div className={styles.section}>
          <div className={styles.header}>
            <div className={styles.slackAppLogo}></div>
            <h2 className={styles.imageHeader}>
              {process.env.GATSBY_PRODUCT_NAME} Slack bot
            </h2>
          </div>
          <div className={styles.explanation}>
            <div className={styles.description}>
              <p>
                Our Slack application allows you to access your data by running
                one simple command.
              </p>
              <p>
                <b>
                  The {process.env.GATSBY_SLACK_SLASH_COMMAND} &lt;metric
                  name&gt;
                </b>{' '}
                command will show you a graph with the data you are looking for.
              </p>
              <p>
                You can connect {process.env.GATSBY_PRODUCT_NAME} with your
                Stripe, Intercom account or send your data to us via our{' '}
                <a href="/docs/api">public API</a>.
              </p>
            </div>
            <div className={styles.imageContainer}>
              <div className={styles.image}>
                <SlackMrrImage />
              </div>
            </div>
          </div>
        </div>
        <FinanceMetrics />
        <ProductMetrics />
        <MetricSegmentation />
        <WeeklyReports />
      </FullWidth>
    </div>
  )
}

export default SlackApp
