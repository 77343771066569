import React from 'react'
import Tabs, { growthColor } from './tabs'
import SlackAppTimeToReplyImage from './images/slackAppTimeToReplyImage'
import SlackAppTickets from './images/slackAppTickets'

import * as styles from './section.module.css'

const FinanceMetrics = () => {
  return (
    <div>
      <div className={styles.header}>
        <h2>Customer Support</h2>
      </div>
      <Tabs activeColor={growthColor}>
        <div label={`${process.env.GATSBY_SLACK_SLASH_COMMAND} time to reply`}>
          <h3>Time to reply</h3>
          <p>
            This command shows you a linear graph showing what is the average
            time to first reply on your support tickets.
            <br />
            <b>
              {process.env.GATSBY_SLACK_SLASH_COMMAND} time to reply weekly
            </b>{' '}
            will show the same data in a weekly resolution.
          </p>
          <div className={styles.slackAppImage}>
            <SlackAppTimeToReplyImage />
          </div>
        </div>
        <div label={`${process.env.GATSBY_SLACK_SLASH_COMMAND} ticket`}>
          <h3>Number of tickets</h3>
          <p>
            This command shows you a bar graph representing the total number of
            tickets opened each month.
            <br />
            <b>{process.env.GATSBY_SLACK_SLASH_COMMAND} tickets weekly</b> will
            show the same data in a weekly resolution.
          </p>
          <div className={styles.slackAppImage}>
            <SlackAppTickets />
          </div>
        </div>
      </Tabs>
    </div>
  )
}

export default FinanceMetrics
