import React from 'react'
import * as styles from './addToSlack.module.css'
import queryString from 'query-string'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

class AddToSlack extends React.Component {
  constructor(props) {
    super(props)

    this.url = `${process.env.GATSBY_API_URL}/users/auth/slack`

    this.state = {
      slackUrl: `${process.env.GATSBY_API_URL}/users/auth/slack`,
    }
  }

  componentDidMount() {
    if (typeof window === 'undefined') return

    const params = window.location.search
      ? queryString.parse(window.location.search)
      : null

    if (!params || !params.ref) return

    const { slackUrl } = this.state
    this.setState({ slackUrl: `${slackUrl}/?marketing_ref=${params.ref}` })
  }

  render() {
    const { slackUrl } = this.state
    return (
      <OutboundLink className={styles.addToSlack} href={slackUrl}>
        <img
          alt="Add to Slack"
          src="https://platform.slack-edge.com/img/add_to_slack@2x.png"
          srcSet="https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
          height="50px"
        />
      </OutboundLink>
    )
  }
}

export default AddToSlack
