import React from 'react'
import * as styles from './section.module.css'
import WeeklyReportImage from './weeklyReportImage'

const WeeklyReport = () => {
  return (
    <div className={styles.tablessSection}>
      <div className={styles.header}>
        <h2>Weekly Status Updates</h2>
      </div>
      <div className={styles.explanation}>
        <div className={styles.description}>
          <p>
            Every week {process.env.GATSBY_PRODUCT_NAME} will compile your
            performance over the last 7 days into a report and deliver it on
            Slack.{' '}
          </p>
          <p>
            You will get visibility of all your key metrics and a comparison
            with your performance in the preceeding week.{' '}
          </p>
          <p>
            Configure when and where you want reports delivered and never miss
            updates on your progress!
          </p>
        </div>
        <div className={styles.imageContainer}>
          <div className={styles.image}>
            <WeeklyReportImage />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WeeklyReport
