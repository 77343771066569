import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Hero from '../components/hero'
import Integrations from '../components/integrations'
import SlackApp from '../components/slackApp'
import ScheduleDemoSection from '../components/ScheduleDemoSection'
import FooterBar from '../components/footerBar'
import mainpageImg from '../images/mainpage.png'
import twitterImg from '../images/twitter.png'
import WebReports from '../components/webReports'

const title = () => process.env.GATSBY_APP_URL.replace(/^https:\/\//i, '')

const IndexPage = () => (
  <Layout trialCta pricing>
    <Seo
      title={title()}
      keywords={[
        `analytics`,
        `metrics`,
        `saas`,
        'report',
        'mrr',
        'churn',
        'trials',
        'latency',
        'time to response',
      ]}
      twitterImg={twitterImg}
      mainpageImg={mainpageImg}
    />
    <Hero />
    <WebReports />
    <SlackApp />
    <Integrations />
    <ScheduleDemoSection />
    <hr />
    <FooterBar />
  </Layout>
)

export default IndexPage
