import React from 'react'

import * as styles from './tabs.module.css'

const Tab = props => {
  const onClick = () => {
    const { label, onClick } = props
    onClick(label)
  }

  const { activeTab, label, activeColor } = props

  let className = styles.tabListItem

  if (activeTab === label) {
    className += ' ' + styles.tabListActive + ' ' + activeColor
  }

  return (
    <li className={className} onClick={onClick}>
      {label}
    </li>
  )
}

class Tabs extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: React.Children.toArray(props.children)[0].props.label,
    }
  }

  onClickTabItem = tab => {
    this.setState({ activeTab: tab })
  }

  render() {
    const { activeTab } = this.state
    const onClickTabItem = this.onClickTabItem
    const children = React.Children.toArray(this.props.children)
    const { activeColor } = this.props

    return (
      <div className={styles.tabs}>
        <nav>
          <ul>
            {children.map(child => {
              const { label } = child.props
              return (
                <Tab
                  activeTab={activeTab}
                  activeColor={activeColor}
                  key={label}
                  label={label}
                  onClick={onClickTabItem}
                />
              )
            })}
          </ul>
        </nav>
        <div className={styles.tabContent}>
          {children.map(child => {
            if (child.props.label !== activeTab) return undefined
            return child.props.children
          })}
        </div>
      </div>
    )
  }
}

export const growthColor = styles.activeTabBorderColorGrowth
export const customersColor = styles.activeTabBorderColorCustomers
export const technologyColor = styles.activeTabBorderColorTechnology

export default Tabs
