import React from 'react'
import FullWidth from './fullWidth'

import * as styles from './footerBar.module.css'

const FooterBar = () => {
  return (
    <FullWidth>
      <div className={styles.footerBar}>
        <a href="https://blog.getprobe.io/">Probe Blog</a>&nbsp;&nbsp;
        <a href={`mailto:${process.env.GATSBY_SUPPORT_EMAIL}`}>Contact Us</a>
        &nbsp;&nbsp;
        <a href="/privacy">Privacy &amp; terms</a>
      </div>
    </FullWidth>
  )
}

export default FooterBar
