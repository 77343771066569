import React from 'react'
import MetricSegmentationImage from './metricSegmentationImage'

import * as styles from './section.module.css'

const MetricSegmentation = () => {
  return (
    <div className={styles.tablessSection}>
      <div className={styles.header}>
        <h2>Metric segmentation</h2>
      </div>
      <div className={styles.explanation}>
        <div className={styles.imageContainerLeft}>
          <div className={styles.image}>
            <MetricSegmentationImage />
          </div>
        </div>
        <div className={styles.descriptionRight}>
          <p>Get deeper understanding of your metrics by using segmentation.</p>
          <p>
            Connect HubSpot and pick customer/company properties and we will
            synchronize them for you to segment by Marketing Channel, Industry
            or Country.
          </p>
          <p>
            You can also use our{' '}
            <a href={`${process.env.GATSBY_APP_URL}/docs/api`}>public API</a> to
            enrich your customers with segmentation attributes.
          </p>
        </div>
      </div>
    </div>
  )
}

export default MetricSegmentation
