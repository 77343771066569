// extracted by mini-css-extract-plugin
export var integrationsContent = "integrations-module--integrationsContent--OCUC3";
export var integrations = "integrations-module--integrations--lC-zE";
export var logo = "integrations-module--logo--0-8Yg";
export var stripeLogo = "integrations-module--stripeLogo--OrQxl";
export var chargebeeLogo = "integrations-module--chargebeeLogo--EBgfh";
export var slackLogo = "integrations-module--slackLogo--NVN7H";
export var intercomLogo = "integrations-module--intercomLogo--akkeL";
export var zendeskLogo = "integrations-module--zendeskLogo--LcTxt";
export var hubspotLogo = "integrations-module--hubspotLogo--Wg104";
export var fakturowniaLogo = "integrations-module--fakturowniaLogo--E0WM1";
export var infaktLogo = "integrations-module--infaktLogo--xHGmF";
export var wfirmaLogo = "integrations-module--wfirmaLogo--TLXZ3";
export var ifirmaLogo = "integrations-module--ifirmaLogo--f0GQR";