import React from 'react'
import HeroImage from './heroImage'
import FullWidth from './fullWidth'
import AddToSlack from './addToSlack'
import queryString from 'query-string'
import * as styles from './hero.module.css'

class Hero extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fakturowniaOrigin: null,
    }
  }

  componentDidMount() {
    if (typeof window === 'undefined') return

    const params = window.location.search
      ? queryString.parse(window.location.search)
      : null

    if (!params || !params.ref) return

    const fakturowniaOrigin = params && params['ref'].includes('fakturownia')

    this.setState({ fakturowniaOrigin: fakturowniaOrigin })
  }

  heroTitle = fakturowniaOrigin => {
    if (fakturowniaOrigin) {
      return "Integrate with Fakturownia and get your company's metrics in Slack"
    }
    return 'All-in-one analytics for your SaaS business'
  }

  render() {
    const { fakturowniaOrigin } = this.state
    return (
      <>
        <FullWidth backgroundColorClass={styles.lightBlue}>
          <div className={styles.heroContent}>
            <div className={styles.heroCopy}>
              <h1 className={styles.title}>
                {this.heroTitle(fakturowniaOrigin)}
              </h1>
              <p className={styles.subtitle}>
                {process.env.GATSBY_PRODUCT_NAME} is SaaS analytics software. It
                integrates with tools you are using to run your business and
                gives you access to financial and customer support metrics with
                one simple Slack command.
              </p>
              <AddToSlack />
              <p className={styles.trial}>
                {process.env.GATSBY_PRODUCT_PRICE_TRIAL_DAYS}-day free trial, no
                card required.
              </p>
              {fakturowniaOrigin && (
                <p className={styles.subtitle}>
                  <a href={`mailto:${process.env.GATSBY_SUPPORT_EMAIL}`}>
                    Contact us
                  </a>{' '}
                  to enable <b>Fakturownia</b> integration.
                </p>
              )}
            </div>
            <div className={styles.heroImage}>
              <HeroImage />
            </div>
          </div>
        </FullWidth>
      </>
    )
  }
}

export default Hero
