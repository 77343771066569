import React from 'react'
import Tabs, { growthColor } from './tabs'
import SlackAppMrrImage from './images/slackAppMrrImage'
import SlackAppNewMrrImage from './images/slackAppNewMrrImage'
import SlackAppChurnImage from './images/slackAppChurnImage'
import SlackAppChurnRateImage from './images/slackAppChurnRateImage'
import SlackAppRenewalImage from './images/slackAppRenewalImage'

import * as styles from './section.module.css'

const FinanceMetrics = () => {
  return (
    <div>
      <div className={styles.header}>
        <h2>Finance</h2>
      </div>
      <Tabs activeColor={growthColor}>
        <div label={`${process.env.GATSBY_SLACK_SLASH_COMMAND} mrr`}>
          <h3>Total MRR</h3>
          <p>
            This command shows you a linear graph representing your total MRR
            over the last 6 months. You can run{' '}
            <b>{process.env.GATSBY_SLACK_SLASH_COMMAND} mrr weekly</b> to see
            the same data in a weekly resolution.
          </p>
          <div className={styles.slackAppImage}>
            <SlackAppMrrImage />
          </div>
        </div>
        <div label={`${process.env.GATSBY_SLACK_SLASH_COMMAND} new mrr`}>
          <h3>New MRR</h3>
          <p>
            This command shows you a linear graph representing your new business
            MRR over the last 6 months. You can run{' '}
            <b>{process.env.GATSBY_SLACK_SLASH_COMMAND} new mrr weekly</b> to
            see the same data in a weekly resolution.
            <br />
            <b>{process.env.GATSBY_SLACK_SLASH_COMMAND} new mrr net</b> will
            show you the results of a net calculation.
          </p>
          <div className={styles.slackAppImage}>
            <SlackAppNewMrrImage />
          </div>
        </div>
        <div label={`${process.env.GATSBY_SLACK_SLASH_COMMAND} renewal`}>
          <h3>Existing MRR movements</h3>
          <p>
            This command shows you a stacked bar graph representing your
            existing MRR movements. You can access the same graph by running the{' '}
            <b>{process.env.GATSBY_SLACK_SLASH_COMMAND} expansion</b> or{' '}
            <b>{process.env.GATSBY_SLACK_SLASH_COMMAND} contraction</b>{' '}
            commands. Add <b>weekly</b> to the command to see the same data in a
            weekly resolution.
          </p>
          <div className={styles.slackAppImage}>
            <SlackAppRenewalImage />
          </div>
        </div>
        <div label={`${process.env.GATSBY_SLACK_SLASH_COMMAND} churn`}>
          <h3>MRR Churn</h3>
          <p>
            This command shows you a bar graph representing your MRR churn. You
            can run <b>{process.env.GATSBY_SLACK_SLASH_COMMAND} churn net</b> to
            understand your net MRR churn.
            <br />
            <b>{process.env.GATSBY_SLACK_SLASH_COMMAND} churn weekly</b> will
            show the same data in a weekly resolution
          </p>
          <div className={styles.slackAppImage}>
            <SlackAppChurnImage />
          </div>
        </div>
        <div label={`${process.env.GATSBY_SLACK_SLASH_COMMAND} churn rate`}>
          <h3>MRR Churn Rate</h3>
          <p>
            This command shows you a bar graph representing the percentage of
            your MRR churned each month.
            <br />
            <b>
              {process.env.GATSBY_SLACK_SLASH_COMMAND} churn rate weekly
            </b>{' '}
            will show you data in a weekly resolution.
          </p>
          <div className={styles.slackAppImage}>
            <SlackAppChurnRateImage />
          </div>
        </div>
      </Tabs>
    </div>
  )
}

export default FinanceMetrics
