// extracted by mini-css-extract-plugin
export var slackAppLogo = "section-module--slackAppLogo--6--p+";
export var lightBlue = "section-module--lightBlue---aEuI";
export var white = "section-module--white--RZHnG";
export var suggestion = "section-module--suggestion--rko2y";
export var image = "section-module--image--Ox6Wd";
export var description = "section-module--description--LFjTA";
export var descriptionRight = "section-module--descriptionRight--CGr4p";
export var imageContainer = "section-module--imageContainer--dtpD0";
export var imageContainerLeft = "section-module--imageContainerLeft--bZZAi";
export var section = "section-module--section--tvqjQ";
export var header = "section-module--header--EyY+f";
export var imageHeader = "section-module--imageHeader--teIH+";
export var explanation = "section-module--explanation--WPukK";
export var slackAppImage = "section-module--slackAppImage--nwh8+";
export var tablessSection = "section-module--tablessSection--Q89Mb";